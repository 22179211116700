<template>
  <a-modal
    class="product-form"
    :title="rowData.name"
    style="top: 8px"
    :width="1200"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      labelAlign="right"
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-divider orientation="left">基础信息</a-divider>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品名称">
            <a-form-item class="info-item">{{ rowData.name }}</a-form-item>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="商品分类">
            <a-form-item class="info-item">{{ rowData.parentCategoryName }}</a-form-item>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品品牌">
            <a-form-item class="info-item">{{ rowData.brandName }}</a-form-item>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="备注">
            <a-form-item class="info-item">{{ rowData.remark }}</a-form-item>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="单位">
            <a-form-item class="info-item">{{ proUnitName }}</a-form-item>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="关键字">
            <a-form-item class="info-item">{{ rowData.proKeywords }}</a-form-item>
          </a-form-model-item>
        </a-col>
      </a-row>
<!--      <a-row>-->
<!--        <a-col :md="12">-->
<!--          <a-form-model-item label="供应商">-->
<!--            <a-form-item class="info-item">{{ rowData.proKeywords }}</a-form-item>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--        <a-col :md="12">-->
<!--          <a-form-model-item label="审核后上架">-->
<!--            <a-form-item class="info-item">{{ rowData.flagAutoUp ? '是' : '否' }}</a-form-item>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--      </a-row>-->
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品主图" class="buju required_fields" prop="specStr">
            <QiniuUpload :disabled="true" type="image" :value.sync="rowData.imgUrl"></QiniuUpload>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="商品图片" class="buju">
            <QiniuUpload :disabled="true" type="image" :value.sync="url"></QiniuUpload>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-divider orientation="left" v-if="propertyList != null && propertyList.length > 0">属性</a-divider>
      <a-row>
        <a-col :md="12" v-if="propertyList != null && propertyList.length > 0" v-for="(item, index) in propertyList" :key="index">
          <a-form-model-item :label="item.name">
            <a-form-item class="info-item">{{ item.valueObj[0].label }}</a-form-item>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-divider orientation="left" v-if="typeSpecList.length">规格</a-divider>
      <div class="for-itemlis">
        <div class="item-tabbox">
          <a-table
            class="singleTablecls"
            ref="singleTable"
            v-if="typeSpecTableData.length"
            :data-source="typeSpecTableData"
            bordered
            :key="loadingtable"
            :pagination="false"
          >
            <a-table-column :width="50" key="index" data-index="index">
              <template slot-scope="scope, record, index">{{ index + 1 }}</template>
            </a-table-column>
            <a-table-column title="主图" :width="150" key="imgUrl" data-index="imgUrl">
              <template slot-scope="scope, record">
                <div>
                  <QiniuUpload :disabled="true" type="image" :value.sync="record.imgUrl"></QiniuUpload>
                </div>
              </template>
            </a-table-column>
            <a-table-column
              v-if="formThead.length"
              :width="120"
              align="center"
              v-for="(spec, sIndex) in formThead"
              :key="sIndex"
              :title="spec"
            >
              <template slot-scope="scope, record">
                {{ record.goodsSpecList && record.goodsSpecList[sIndex].goodsSpecValue.specValue }}
              </template>
            </a-table-column>
            <a-table-column title="型号" key="barCode" data-index="barCode" :width="150">
              <template slot-scope="scope, record">
                <div>
                  {{ record.barCode }}
                </div>
              </template>
            </a-table-column>
            <a-table-column key="" data-index="" title="功能类型" :width="150">
              <template slot-scope="scope, record">
                <a-select
                  disabled
                  placeholder="请选择"
                  mode="multiple"
                  v-model="record.ids"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in functionType" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </template>
            </a-table-column>
            <a-table-column key="sellPrice" data-index="sellPrice" title="标准开票价（D价）" algin="right" :width="150">
              <template slot-scope="scope, record">
                <div>
                  {{ record.sellPrice == null ? '0.00' : record.sellPrice.toFixed(2) }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="销售库存" key="saleQuantity" data-index="saleQuantity" :width="150">
              <template slot-scope="scope, record">
                <div>
                  {{ record.saleQuantity }}
                </div>
              </template>
            </a-table-column>
            <a-table-column
              :render-header="renderHeader"
              title="物料编码"
              key="goodsCode"
              data-index="goodsCode"
              :width="150"
            >
              <template slot-scope="scope, record">
                <div>
                  {{ record.goodsCode }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="商家编码" key="sellerCode" data-index="sellerCode" :width="150">
              <template slot-scope="scope, record">
                <div>
                  {{ record.sellerCode }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="积分获取比例" key="skuIntegralSetting" data-index="skuIntegralSetting" :width="150">
              <template slot-scope="scope, record">
                <div>
                  {{ record.skuIntegralSetting.obtainProportion }}
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </a-form-model>
    <a-divider orientation="left" v-if="rowData.content != null">详细介绍</a-divider>
    <div v-html="rowData.content"></div>
    <a-divider orientation="left">操作日志</a-divider>
    <a-table
      width="100%"
      :data-source="tableData"
      bordered
      :pagination="page"
      @change="
        (p) => {
          page = p
          toSearch(2)
        }
      "
    >
      <a-table-column title="操作人" data-index="createUser" :ellipsis="true" align="center"> </a-table-column>
      <a-table-column title="操作日志" data-index="logRemark" :ellipsis="true" align="center"> </a-table-column>
      <a-table-column title="操作时间" data-index="createTime" :ellipsis="true" align="center"> </a-table-column>
    </a-table>
    <div class="footer-bts">
      <a-button key="back" @click="handleCancel">关闭</a-button>
    </div>
  </a-modal>
</template>
<script>
const qs = require('qs')

import { doExchange } from '../api/ProductGoodsApi'
import 'element-ui/lib/theme-chalk/index.css'

export default {
  created() {},
  data() {
    return {
      queryParam: {},
      url: '',
      brandList: [],
      // 规格
      typeSpecList: [],
      selectSkuInfo: {},
      typeSpecTableData: [],
      typeSpecArr: [],
      loadingtable: false,
      specNewVal: '',
      specOldVal: '',
      specList: [],
      proValueList: {},
      infoSpecName: 'default',
      tagList: [],
      parentIds: [],
      categoryList: [],
      content: '',
      carDisplacement: [],
      loading: false,
      masterloading: false,
      masterImageUrl: '',
      imageUrl: '',
      value: '',
      visible: false,
      handle: 'add',
      rowData: {},
      fileList: [],
      options: [],
      propertyList: [],
      categoryId: 0,
      specvalueId: '',
      brandId: [],
      categoryIds: [],
      data: [],
      carList: [],
      displacement: [],
      tableData: [],
      proValue: {},
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      functionType: [],
      proUnitName:''
    }
  },

  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      const that = this
      that.rowData = row
      that.typeSpecTableData = []
      that.typeSpecList = []
      that.categoryIds = []
      that.propertyList = []
      that.brandId = []
      that.carDisplacement = []
      that.typeSpecArr = []
      if (handle == 'add') {
      } else if (handle == 'edit' || handle == 'check' || handle == 'look') {
        let id = handle == 'edit' ? row.id : handle == 'check' ? row.id : row.productId
        that.axios.get('/api/product/system/info/selectById/' + id).then((res) => {
          that.queryByCategory(row.categoryId, res.body.goodsList, 2, res)
          that.rowData = res.body
          that.carDisplacement = [
            {
              key: that.rowData.carDisplacementId,
              label: that.rowData.carDisplacement,
            },
          ]
          that.brandId = [
            {
              label: that.rowData.brandName,
              key: that.rowData.brandId,
            },
          ]
        })
        console.log(that.rowData.proUnit)
        this.axios.get(`/api/product/productGoodsUnit/selectById/${that.rowData.proUnit}`).then(res =>{
          that.proUnitName = res.body.name
        console.log(res)
      })
      } else if (handle == 'cusCheck') {
        that.axios.get('/api/product/system/info/selectById/' + row.productIdByGoodsId).then((res) => {
          that.rowData = res.body
          that.brandId = [
            {
              label: that.rowData.brandName,
              key: that.rowData.brandId,
            },
          ]
          const specList = []
          const plist = res.body.propertyList;
          if(plist != null && plist.length >0){
            plist.forEach((item) => {
              const value = {
                name: item.propertyName,
                id: item.productBasePropertyId,
              }
              specList.push(
                Object.assign({}, value, {
                  valueObj: [
                    {
                      key: item.propertyValuesList[0].productBasePropertyValueId,
                      label: item.propertyValuesList[0].propertyValue,
                    },
                  ],
                })
              )
            })
          }
          that.propertyList = specList
          that.categoryIds = res.body.parentIdOrg.split('_').map(Number)
          that.url = res.body.pictureList
            ? res.body.pictureList
                .map((e) => {
                  return e.url
                })
                .toString()
            : ''
        })
      }
      this.queryParam.id = row.id
      const postData = Object.assign({}, this.queryParam, this.page)
      this.axios.get(`/api/product/product/productTrackLog/list?${qs.stringify(postData)}`).then((res) => {
        this.tableData = res.body.records
        this.page.total = res.body.total
      })

      // 获取标签列表
      // this.axios.get('/api/product/system/tag/list').then(res => {
      //   this.tagList = res.body.records
      // })

      // 获取分类列表
      this.axios.get('/api/product/category/categoryList?FlagEnable=true').then((res) => {
        this.categoryList = res.body[0] ? res.body[0].children : []
      })

      // 获取功能类型列表
      this.axios.get('/api/product/product/productFunctionType/list?pageSize=99999').then((res) => {
        this.functionType = res.body.records
      })

      this.visible = true
    },
    reShowData(data) {
      if (data.length) {
        let result = {
          typeSpecArr: [],
          specOldVal: [],
        }
        let resultObj = {}
        data.forEach((item) => {
          item.goodsSpecList.forEach((sitem) => {
            let str = `${sitem.specId}-${sitem.specName}-${sitem.goodsSpecValue.specValue}`
            if (!resultObj[str]) {
              resultObj[str] = 1
              result.typeSpecArr.push(str)
            }
          })
        })
        this.typeSpecArr = result.typeSpecArr
      }
    },
    toSearch(type) {
      if (type == 1) {
        this.queryParam.startTimeString = null
        this.queryParam.endTimeString = null
        this.resetQueryParam
          ? (this.queryParam = Object.assign({}, this.resetQueryParam))
          : (this.resetQueryParam = Object.assign({}, this.queryParam))
      }
      this.page.pageNumber = type == 2 ? this.page.current : (this.page.current = 1)
      const postData = Object.assign({}, this.queryParam, this.page)
      this.axios
        .get(`/api/product/product/productTrackLog/list?${qs.stringify(postData)}`)
        .then((res) => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        })
        .catch((err) => {
          console.log(err._getMessages())
        })
    },
    queryByCategory(id, list, num, orgSource) {
      this.axios.get('/api/product/spec/queryByCategoryId/' + id).then((res) => {
        this.typeSpecList = res.body
        if (num == 2) {
          if (this.typeSpecList.length <= 0) {
            const data = list
            data.forEach((item, index) => {
              item.goodsSpecList = [
                {
                  specName: '规格',
                  specValue: '无',
                },
              ]
            })
            this.typeSpecTableData = data
          } else {
            this.typeSpecTableData = list
            this.reShowData(list)
          }
        }
      })
      this.axios.get('/api/product/product/baseProperty/queryByCategoryId/' + id).then((res) => {
        this.propertyList = res.body
        const list = []
        const p =  this.propertyList
        if(p != null && p.length >0){
          p.map((item) => {
            list.push(Object.assign({}, item, { valueObj: {} }))
          })
        }
        this.propertyList = list

        const propertyListtemp = []
        orgSource.body.propertyList.forEach((item) => {
          const value = {
            name: item.propertyName,
            id: item.productBasePropertyId,
          }
          propertyListtemp.push(
            Object.assign({}, value, {
              valueObj: [
                {
                  key: item.propertyValuesList[0].productBasePropertyValueId,
                  label: item.propertyValuesList[0].propertyValue,
                },
              ],
            })
          )
        })
        this.propertyList = propertyListtemp
        this.categoryIds = orgSource.body.parentIdOrg.split('_').map(Number)
        this.url = orgSource.body.pictureList
          ? orgSource.body.pictureList
              .map((e) => {
                return e.url
              })
              .toString()
          : ''
      })
    },

    // 禁用弹框
    handleCancel() {
      this.rowData = {}
      this.typeSpecTableData = []
      this.typeSpecList = []
      this.categoryIds = []
      this.propertyList = []
      this.brandId = []
      this.typeSpecArr = []
      this.visible = false
    },

    renderHeader(h, { column, $index }) {
      return (
        <div>
          <span style={{ color: 'red' }}>*</span> {column.label}
        </div>
      )
    },
  },
  watch: {
    typeSpecChoose(newVal, oldVal) {
      this.specNewVal = doExchange(newVal)
      this.specOldVal = doExchange(oldVal)
    },
  },

  computed: {
    typeSpecChoose() {
      let hash = {}
      let result = []
      this.typeSpecArr.forEach((item, index) => {
        if (hash[item.split('卍')[0]]) {
          hash[item.split('卍')[0]].push(item)
        } else {
          hash[item.split('卍')[0]] = []
          hash[item.split('卍')[0]].push(item)
        }
      })
      for (let key in hash) {
        result.push(hash[key])
      }
      return result
    },
    typeSpecChooseResult() {
      let result = doExchange(this.typeSpecChoose)
      let resultArrs = []
      if (this.typeSpecChoose.length) {
        result.forEach((item, index) => {
          let obj = {
            imgUrl: '',
            goodsSpecList: [],
            goodsCode: '', // 货号
            grossWeight: '', // 重量
            costPrice: '', // 预计成本价
            sellPrice: '', // 建议标准开票价（D价）
            marketPrice: '', //市场价
            realQuantity: '', // 真实库存
            saleQuantity: '', // 销售库存
            minQuantity: '', // 起订量
            maxQuantity: '', //限购量
            barCode: '', // 条形码
            sellerCode: '', // 商家编码
            chartCount: '',
            oeCode: '',
            name: this.rowData.name,
          }
          let itemarr = item.split(',')
          let itemResultArr = []
          itemarr.forEach((sItem) => {
            let list = sItem.split('卍')
            itemResultArr.push({
              specId: list[0],
              specName: list[1],
              specValue: list[2],
              goodsSpecValue: {
                specValue: list[2],
                specValueId: this.specvalueId,
              },
            })
          })
          obj.goodsSpecList = itemResultArr
          resultArrs.push(obj)
        })
      }
      return resultArrs
    },
    formThead() {
      return (
        this.typeSpecTableData[0].goodsSpecList &&
        this.typeSpecTableData[0].goodsSpecList.map((v) => {
          return v.specName
        })
      )
    },
    // active() {
    //   return this.handle == 'check' ? null : ''
    // }
  },
}
</script>

<style lang="less" scoped>
.product-form {
  /*enzo style*/

  .info-item {
    background-color: #f4f4f4;
    padding-left: 10px;
    border-radius: 3px;
  }

  .footer-bts {
    text-align: right;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #e9e9e9;
  }

  .form-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 15px;

    span {
      color: red;
    }
  }

  .inline {
    display: flex;
    justify-content: space-around;
  }

  .form-label,
  .upload-hint-text {
    line-height: 25px;
  }

  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }

  .upload-list-inline .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
  }

  .upload-list-inline .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
  }

  .buju {
    .ant-col-3 {
      width: 15% !important;
    }

    .ant-form-item-control-wrapper {
      width: 85% !important;
    }
  }

  .ant-form-item {
    margin-bottom: 13px;
  }

  .pub-tabDatadetails {
    position: relative;
    border-left: 1px solid #ced5de;
    border-bottom: 1px solid #ced5de;
    border-top-left-radius: 3px;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      top: 30px;
      border-right: 1px solid #ced5de;
    }

    .el-tabs--card > .el-tabs__header .el-tabs__item {
      //border:1px solid #ced5de;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background: #f3f5f8;
      color: #7b8693;
      border: 1px solid #ced5de;

      &:first-child {
        border-left: none;
      }

      &.is-active {
        color: #20a0ff;
        background: #fff;
        border-bottom: 1px solid transparent;
      }
    }

    .el-tabs__item {
      height: 30px;
      line-height: 30px;
      padding: 0 16px;
      color: #7b8693;
      margin-right: 5px;
    }

    .el-tabs__content {
      padding: 5px 20px 20px;
    }

    .detail_picbox {
      padding: 10px 0;

      img {
        display: block;
        width: 60px;
        height: 60px;
      }
    }
  }

  .rseGds-content {
    margin-bottom: 25px;
    padding-top: 0;
    //width: 1101px;
    .el-dialog__footer {
      border-top: 1px solid #dce2ea;
      padding: 15px 20px;
    }

    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .for-itemlis {
    padding-bottom: 12px;

    .item-head {
      height: 44px;
      line-height: 44px;
      border-bottom: 1px solid #e9e9e9;
      margin: 0 0 15px;

      .item-tit {
        font-size: 14px;
        margin-right: 15px;
      }
    }

    .item-content {
      .listColumn {
        line-height: 20px;
        color: #464c5b;
        margin: 5px 20px;
      }
    }

    .addnewtype {
      font-size: 12px;
      color: #525d6e;
      margin-left: 10px;
      cursor: pointer;

      &.no-margin {
        margin: 3px 0 0 -5px;
      }
    }

    .item-tabbox {
      padding: 0 20px 0px;

      .ditor-01 {
        width: 90%;
        margin-bottom: 20px;

        .typ_lis {
          display: inline-block;
          padding: 4px 20px;
          background: #f5f5f5;
          border: 1px solid #dfe5ec;
          margin: 5px 8px 5px 0px;
          border-radius: 4px;

          &.active {
            border-color: #20a0ff;
            color: #20a0ff;
          }
        }
      }

      .skuImgUpload {
        position: relative;
        background: #f5f6fa;
        display: inline-block;
        width: 60px;
        height: 60px;
        margin: 10px 0;

        img {
          width: 100%;
          height: 100%;
        }

        .skuImgFile {
          width: 100%;
          height: 100%;
          font-size: 100px;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          z-index: 3;
        }

        span {
          width: 30px;
          height: 30px;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          color: #8c939d;
          margin: -15px 0 0 -15px;
          z-index: 2;
        }

        .showImg {
          width: 60px;
          height: 60px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          background-color: #fff;
          border: 1px solid #c2ccd1;

          img {
            width: 100%;
            height: 100%;
          }

          em {
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: #ff0000;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: -9px;
            top: -9px;
          }
        }
      }
    }

    /*商品图片*/

    .item-uploadImg_box {
      padding: 4px 22px;

      ul {
        float: left;

        li {
          position: relative;
          width: 60px;
          height: 60px;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          float: left;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }

          p {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            font-size: 14px;
            text-align: center;
            line-height: 58px;
            color: #fff;
          }
        }
      }

      .uploadImg-btn {
        float: left;
        width: 60px;
        height: 60px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        background: #fbfdff;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .inputFile2 {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 100px;
          z-index: 3;
          opacity: 0;
        }

        span {
          display: inline-block;
          width: 30px;
          height: 30px;
          font-size: 24px;
          text-align: center;
          line-height: 30px;
          color: #8c939d;
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -15px 0 0 -15px;
        }
      }
    }
  }
}
</style>
