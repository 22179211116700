/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-10 16:35:52
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 17:32:17
 */
export const columns = [
    {
        title: '商品名称',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        align:'center',
        // ellipsis:true,
        fixed: 'left',
        scopedSlots: { customRender: 'name'}
    },
    /*{
      title: '大区/事务所',
      dataIndex: 'firm',
      key: 'firm',
      width: 200,
      align:'center',
      scopedSlots: { customRender: 'firm'}
    },
    {
      title: '经销商',
      dataIndex: 'dealerName',
      key: 'dealerName',
      width: 200,
      align:'center',
    },*/
    {
        title: '商品图片',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
        /*width:150,*/
        ellipsis:true,
        align:'center',
        scopedSlots: { customRender: 'imgUrl'}
    },
    {
        title: '标签',
        dataIndex: 'tag',
        key: 'tag',
        width: 100,
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'name'}
    },
    {
        title: '分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width:120,
        align:'center',
      scopedSlots: { customRender: 'categoryName'}
    },
    {
        title: '品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
      title: '型号',
      dataIndex: 'barCode',
      key: 'barCode',
      width:150,
      align: 'center',
      ellipsis: true,
      // scopedSlots: {customRender: 'price'}
    },
    {
        title: '库存',
        dataIndex: 'stock',
        key: 'stock',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '标准开票价（D价）',
        dataIndex: 'sellPrice',
        key: 'sellPrice',
        /*width:150,*/
        align: 'center',
        ellipsis: true,
        scopedSlots: {customRender: 'price'}
    },
    {
        title: '上架状态',
        dataIndex: 'flagUp',
        key: 'flagUp',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'flagUp'}
    },
    {
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'createTime',
        width:200,
        align:'center',
        ellipsis:true
    },
    // {
    //     title: '操作',
    //     dataIndex: 'action',
    //     key: 'action',
    //     width:150,
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'action'}
    // },
]